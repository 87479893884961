<template>
  <div>
    <v-dialog v-model="show_dialog" width="500">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          Reject
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog()">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2 card-text-wrapper">
          <v-row class="ma-0 add-reason-wrapper ">
            <v-col
              cols="9"
              class="pb-0"
              v-if="getPermission('Add Rejection Reason')"
            >
              <v-form ref="add_rejection_reason_field">
                <v-text-field
                  class="add-reason-field"
                  v-model="new_rejection_reason"
                  outlined
                  hide-details
                  :rules="$rules.REQUIRED_FIELD"
                  label="New Rejection Reason"
                  dense
                  v-if="show_add_rejection_reason"
                  placeholder="Enter new rejection reason"
                >
                </v-text-field>
              </v-form>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
              v-if="getPermission('Add Rejection Reason')"
            >
              <div class="btns-wrapper">
                <v-btn
                  color="secondary_2"
                  rounded
                  small
                  text
                  outlined
                  class="mx-1 mb-3"
                  v-if="!show_add_rejection_reason"
                  @click="show_add_rejection_reason = true"
                >
                  <!-- <v-icon>mdi-cancel</v-icon> -->
                  + Add Reason
                </v-btn>
                <v-btn
                  color="secondary_2"
                  rounded
                  small
                  class="mx-1 white--text"
                  v-if="show_add_rejection_reason"
                  @click="saveReason()"
                  :loading="save_btn_loader"
                  depressed
                  :disabled="save_btn_loader"
                >
                  <!-- <v-icon>mdi-cancel</v-icon> -->
                  Save
                </v-btn>
                <v-btn
                  color="secondary_2"
                  fab
                  x-small
                  text
                  outlined
                  class="mx-1 pa-0"
                  :loading="save_btn_loader"
                  :disabled="save_btn_loader"
                  v-if="show_add_rejection_reason"
                  @click="hideAddRejectionReason()"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <v-form ref="rejection_reason">
                <v-autocomplete
                  v-model="rejection_reason"
                  :items="rejection_reason_list"
                  :loading="rejection_reason_list_loader"
                  :search-input.sync="reason_search"
                  clearable
                  dense
                  hide-details
                  required
                  :rules="$rules.REQURIED_FILED"
                  item-text="rejection_reason"
                  item-value="rejection_reason"
                  label="Select reason"
                  outlined
                  placeholder="Select reason"
                  @focus="getRejectionList"
                >
                </v-autocomplete>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pb-4">
          <v-btn
            color="red darken-1"
            rounded
            class="mx-1 white--text px-4"
            @click="rejectLead()"
            :loading="reject_btn_loader"
            depressed
            :disabled="reject_btn_loader"
          >
            <!-- <v-icon>mdi-cancel</v-icon> -->
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      customer_id: "",
      customer_details: {},
      rejection_reason_list_loader: false,
      rejection_reason_list: [],
      rejection_reason: "",
      reason_search: "",
      new_rejection_reason: "",
      show_add_rejection_reason: false,
      save_btn_loader: false,
      reject_btn_loader: false,
    };
  },
  computed: {},
  methods: {
    async openRejectPendingLead(value) {
      // console.log("openRejectPendingLead", value);
      this.show_dialog = true;
      this.customer_id = value.id;
      this.customer_details = value;
      await this.getRejectionList();
    },

    closeDialog() {
      this.show_add_rejection_reason = false;
      this.new_rejection_reason = "";
      this.rejection_reason = "";
      this.show_dialog = false;
    },
    hideAddRejectionReason() {
      this.new_rejection_reason = "";
      this.show_add_rejection_reason = false;
    },
    getRejectionList() {
      const self = this;

      self.rejection_reason_list_loader = true;
      const successHandler = (res) => {
        self.rejection_reason_list = res.data.result;
      };
      const finallyHandler = () => {
        self.rejection_reason_list_loader = false;
      };

      self.request_GET(
        self,
        self.$urls.GET_CREATE_NBFC_REJECTION_REASON,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    saveReason() {
      if (this.new_rejection_reason) {
        const self = this;
        self.save_btn_loader = true;

        let form = {
          rejection_reason: this.new_rejection_reason,
        };

        const successHandler = () => {
          self.save_btn_loader = false;
          self.show_add_rejection_reason = false;
          this.new_rejection_reason = "";
          self.getRejectionList();
        };
        const finallyHandler = () => {
          self.save_btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.GET_CREATE_NBFC_REJECTION_REASON,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "Please enter a new reason before saving");
      }
    },
    rejectLead() {
      if (this.rejection_reason && this.customer_id) {
        const self = this;
        self.reject_btn_loader = true;

        // let lead_id = this.customer_id;

        let form = {
          rejection_reason: this.rejection_reason,
        };

        const successHandler = () => {
          self.reject_btn_loader = false;
          this.show_add_rejection_reason = false;
          // this.rejection_reason = "";
          this.$refs.rejection_reason.reset();
          this.$emit("update");
          this.show_dialog = false;
        };
        const finallyHandler = () => {
          self.reject_btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.REJECT_LEAD_BY_NBFC + `${this.customer_id}/`,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "Please select a reason before rejecting");
      }
    },
  },
};
</script>
<style scoped>
.card-text-wrapper {
  overflow-x: hidden;
}
.add-reason-wrapper {
  position: relative;
  /* height: 60px; */
}
.btns-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  /* position: absolute; */
  right: 15px;
  top: 18px;
}
.add-reason-field {
  transform-origin: right;
  transform: translateX(900px);
  animation: reason_field 200ms linear forwards;
}
@keyframes reason_field {
  to {
    transform: translateX(0px);
  }
}
</style>
